import React from "react"
import fishmednet from "../images/fishmednet.png"
import eni from "../images/eni.png"
import eu from "../images/eu.png"
import sardegna from "../images/sardegna.png"
import legacoop from "../images/legacoop.svg"
import gnucoop from "../images/gnucoop.svg"

const Footer = () => (
  <div>
    <a id="preFooter" target="_blank" href="https://www.enicbcmed.eu/projects/fish-med-net">
      <span><img src={eni}/></span>
      <span><img src={eu}/></span>
      <span><img src={sardegna}/></span>
      <br/>
      <img src={fishmednet} id="fishmednet"/>
    </a>
    <footer>
      <a href="https://www.legacoop.coop/" target="_blank"><img src={legacoop}/></a>
      <a href="https://www.gnucoop.com/" target="_blank"><img src={gnucoop}/></a>
      <p>
        This platform has been produced thanks to the financial support
        of the European Union under the ENI CBC Mediterranean Sea Basin Programme
        with a total financing of total budget EUR 2.242.131,50.
        <br/>
        The contents of this platform are the sole responsibility of Legacoop Agroalimentare
        and can under no circumstances be regarded as reflecting the position
        of the European Union or the Programme management structures.
      </p>
    </footer>
    <div id="copyright">
      © Copyright
    </div>
  </div>
)

export default Footer
