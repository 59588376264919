import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { getFisherData } from "../utils"

function randomFishers(fishers, location, slug) {
  let seed = 1
  for (let i = 0; i < slug.length; i++) {
    seed += slug.charCodeAt(i)
  }
  const random = () => {
    const x = Math.sin(seed++) * 10000
    return x - Math.floor(x)
  }

  const n = 4
  fishers = fishers.filter(f => !location || f.location === location)
  if (fishers.length <= n) {
    return fishers
  }
  const selected = []
  for (let i = 0; i < n; i++) {
    const r = Math.floor(random() * fishers.length)
    const f = fishers.splice(r, 1)[0]
    selected.push(f)
  }
  return selected
}

const Explore = props => (
  <StaticQuery
    query={graphql`
      query {
        hasuraQuery {
          form_data(where: {form_status_ref_id: {_eq: "d1968f35-62fb-445a-a3df-230fd5a605e0"}}) {
            data
            location_ref_id
          }
        }
      }
    `}
    render={data => (
      <div id="explore" className="big-margin-bottom">
        {randomFishers(getFisherData(data), props.location, props.slug).map(fisher => (
          <div>
            <div className="explore-scale">
              <img src={fisher.image1 && fisher.image1.url}/>
              <div className="red-layer pos-absolute"></div>
              <div className="explore-text pos-absolute">
                <h6>{fisher.name}</h6>
                <hr/>
                <span>{fisher.description}</span>
                <a href={'/' + fisher.slug}>Explorer et contacter</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  />
)

export default Explore
