import React from "react"
import menuIcon from "../images/hamburger-menu.png"
import logo from "../images/logo.svg"

function onLangChange(event) {
  const val = event.target.value
  if (val && typeof(window) !== 'undefined') {
    window.location.href = `https://${val}.fishmednet.com/`
  }
}

const Header = () => (
  <header className="content-pad">
    <a href="/"><img src={logo}/></a>
    <div>
      <select onChange={onLangChange}>
        <option value="fr">Français</option>
        <option value="www">English</option>
        <option value="ar">عربي</option>
        <option value="it">Italiano</option>
      </select>
    </div>
  </header>
)

export default Header
