function getFisherData(queryData) {
	return queryData.hasuraQuery.form_data.map(fd => (
		{
			...fd.data,
			lat: Number(fd.data.lat) || 0,
			lon: Number(fd.data.lon) || 0,
			location: fd.location_ref_id,
		}
	))
}

module.exports.getFisherData = getFisherData
